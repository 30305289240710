'use strict';

export default class Tab {
	constructor(options) {

		this._polyfill();

		Object.assign(this._options = {}, this._default(), options);
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this._init();
			});
		} else {
			this._init();
		}
	}

	_default() {
		return {
			classActiveLink: 'is-active',
			classActiveTab: 'is-active',
			dataAttributeLink: 'data-tab-link',
			dataAttributeTab: 'data-tab-target',
			dataAttributeGroup: 'data-tab-group',
			onTabChange: function () {

			}
		}
	}

	_init() {

		document.body.addEventListener('click', (e) => {

			let currentLink = e.target.closest('[' + this._options.dataAttributeLink + ']');

			if (currentLink && !currentLink.classList.contains(this._options.classActiveLink)) {
				e.preventDefault();
				let ind = currentLink.getAttribute(this._options.dataAttributeLink),
					group = currentLink.getAttribute(this._options.dataAttributeGroup),
					link = document.querySelectorAll(`[${this._options.dataAttributeLink}][${this._options.dataAttributeGroup}=${group}].${this._options.classActiveLink}`),
					screen = document.querySelectorAll(`[${this._options.dataAttributeTab}][${this._options.dataAttributeGroup}=${group}].${this._options.classActiveTab}`),
					currentScreen = document.querySelectorAll(`[${this._options.dataAttributeTab}=${ind}][${this._options.dataAttributeGroup}=${group}]`)[0];

				if (link[0]) link[0].classList.remove(this._options.classActiveLink);
				if (screen[0]) screen[0].classList.remove(this._options.classActiveTab);
				currentLink.classList.add(this._options.classActiveLink);
				currentScreen.classList.add(this._options.classActiveTab);

				if (this._options.onTabChange && typeof this._options.onTabChange === 'function') this._options.onTabChange(currentScreen);
			}

		});

	}

	_polyfill() {
		//matches
		if (!Element.prototype.matches) {
			Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
		}

		//closest
		if (!Element.prototype.closest) {
			Element.prototype.closest = function (css) {
				let node = this;
				while (node) {
					if (node.matches(css)) return node;
					else node = node.parentElement;
				}
				return null;
			};
		}

		//assign
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}


	}

}
