import $ from 'jquery'
import Tab from '../modules/module.tab'
import Popup from '../modules/module.popup'
import PageNav from '../modules/module.pagenav'
import AlignHeight from '../modules/module.alignheight'
import ViewPort from '../modules/module.viewport'
import TimeCounter from '../modules/module.timecounter'
import ObjectFit from '../modules/module.objectfit'
import 'slick-carousel'
import Viewer from 'viewerjs'


window.app = window.app || {};

Array.prototype.entrance = function(array) {

	let result = false;

	if (!array) {
		return result;
	}

	for (let i = 0; i < this.length; i++ ){
		let el = this[i];

		for (let j = 0; j < array.length; j++ ){
			if ( el === array[j] ) {
				result = true;
				// console.info(result, el);
				break;
			}
		}

		if (result) break;
	}

	return result;
};



$(function () {

	new ObjectFit({imageContainer: '.photos__item-image'});

	$('.js-gallery-group').each(function () {

		let $group = $(this);

		if ( !$group.attr('data-viewerjs') ){
			$group.attr('data-viewerjs', true);

			let viewer = new Viewer($group[0], {
				url: 'data-original',
				button: false,
				navbar: false,
				rotatable: true,
				scalable: true,
				transition: false,
				fullscreen: true,
				keyboard: true,
				title: false,
				toolbar: false,
				tooltip: false,
				movable: false,
				zoomable: false,
				hidden: function (e) {
					document.getElementsByTagName('body')[0].style.paddingRight = '';
				},

				viewed: function (e) {
					// console.log(e);

					let $control = $('<div class="slider-control"><div class="slider-control__close"></div><div class="slider-control__arrow slider-control__arrow_prev"></div><div class="slider-control__arrow slider-control__arrow_next"></div></div>').insertAfter($(e.detail.image));

					$control.css({
						width: e.detail.image.offsetWidth,
						height: e.detail.image.offsetHeight,
						top: e.detail.image.offsetTop
					});

					$control
						.on('click', '.slider-control__close', ()=>{
							viewer.hide();
						})
						.on('click', '.slider-control__arrow_prev', ()=>{
							viewer.prev(true);
						})
						.on('click', '.slider-control__arrow_next', ()=>{
							viewer.next(true);
						})
					;

					document.getElementsByTagName('body')[0].style.paddingRight = scrollBarWidth() + 'px';
				}
			});
		}

	});


	window.app.popup = new Popup({
		bodyClass: 'is-blur'
	});

	window.app.timeCounter = new TimeCounter();

	let alignHeight = {};

	alignHeight.cards = new AlignHeight({
		wrap: '.cards__list',
		item: '.cards__item-inner'
	});

	alignHeight.member = new AlignHeight({
		wrap: '.member__list',
		item: '.member__item-inner'
	});

	alignHeight.vacancy = new AlignHeight({
		wrap: '.vacancy__list',
		item: '.vacancy__item-inner'
	});

	function alignHeightTrigger() {

		for (let i = 0; i < Object.keys(alignHeight).length; i++){
			if ( window.app.isMobile ){
				if ( $(alignHeight[Object.keys(alignHeight)[i]]._options.wrap).hasClass('slick-slider') ){
					alignHeight[Object.keys(alignHeight)[i]].alignHeight();
				} else {
					alignHeight[Object.keys(alignHeight)[i]].clearHeight();
				}
			} else {
				alignHeight[Object.keys(alignHeight)[i]].alignHeight();
			}
		}
	}

	new PageNav({
		heightElements: ['.header', '.section_menu'],
		activeClass: 'is-active',
		offset: 20
	});

	 new Tab({
		onTabChange: (tab)=>{

			$(tab).find('.js-schedule-content').slick('setPosition');
			$(tab).find('.js-schedule-slider').slick('setPosition');
			$(tab).find('.js-adaptive-slider').slick('setPosition');
			$(tab).find('.js-content-slider').slick('setPosition');
			$(tab).find('.js-mobile-slider').slick('setPosition');
			$(tab).find('.js-block-slider').slick('setPosition');

			alignHeightTrigger();
		}
	});




	let viewPort = new ViewPort({
		'0': ()=>{
			setMobileSlick();
			setScheduleContentSlick();
			window.app.isMobile = true;
			window.app.isDesktop = false;
			window.app.viewPort = 0;

			setMenuSlide(false);
			alignHeightTrigger();

		},
		'1260': ()=>{
			setMobileSlick(true);
			setScheduleContentSlick(true);
			window.app.isMobile = false;
			window.app.isDesktop = true;
			window.app.viewPort = 1;

			setMenuSlide();
			alignHeightTrigger();

		},
		'1660': ()=>{
			setMobileSlick(true);
			setScheduleContentSlick(true);
			window.app.isMobile = false;
			window.app.isDesktop = true;
			window.app.viewPort = 2;
			setMenuSlide();
			alignHeightTrigger();
		}
	});

	setAdaptiveSlick();
	setContentSlick();
	setBlockSlick();

	let $b = $('body');

	$('.js-schedule-slider').each(function () {
		let $slider = $(this),
			$wrap = $slider.parent();

		$slider
			.slick({
				dots: false,
				infinite: false,
				speed: 300,
				swipeToSlide: false,
				slidesToShow: 7,
				slidesToScroll: 7,
				adaptiveHeight: true,
				prevArrow: $wrap.find('.slider-control__arrow_prev'),
				nextArrow: $wrap.find('.slider-control__arrow_next'),
				responsive: [
					{
						breakpoint: 1660,
						settings: {
							slidesToShow: 5,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1260,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
		});
	});

	$('.js-schedule-all').each(function () {
		let $wrap = $(this),
			$sliderDays = $wrap.find('.js-schedule-all-days'),
			$slider = $wrap.find('.js-schedule-all-slider')
		;

		$sliderDays
			.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				$slider.slick('slickGoTo', nextSlide);
			})
			.slick({
				dots: false,
				infinite: false,
				speed: 300,
				swipeToSlide: false,
				slidesToShow: 7,
				slidesToScroll: 1,
				// asNavFor: $slider,
				prevArrow: $wrap.find('.slider-control__arrow_prev'),
				nextArrow: $wrap.find('.slider-control__arrow_next'),
				responsive: [
					{
						breakpoint: 1660,
						settings: {
							slidesToShow: 5,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1260,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
		});

		$slider
			.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
				$sliderDays.slick('slickGoTo', nextSlide);
				$slider.not(slick.$slider).slick('slickGoTo', nextSlide);
			})
			.slick({
				dots: false,
				infinite: false,
				speed: 300,
				swipeToSlide: false,
				slidesToShow: 7,
				slidesToScroll: 1,
				// asNavFor: $sliderDays,
				arrows: false,
				responsive: [
					{
						breakpoint: 1660,
						settings: {
							slidesToShow: 5,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 1260,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
		});
	});




	$b
		.on('mousedown', function (e) {

			if ( $('.filter__select.is-open').size() ){
				if ( !$(e.target).closest('.filter__select.is-open').size() ) $('.filter__select.is-open').removeClass('is-open');
			}

		})
		.on('click', '[data-scrollto]', function (e) {
			e.preventDefault();
			$('html,body').animate({scrollTop:$($(this).data('scrollto')).offset().top - $('.header').height() }, 500);
		})
		.on('click', '.nav__handle', function (e) {
			$b.toggleClass('is-menu-open');
		})
		.on('click', '.footer__group-title', function (e) {
			let $t = $(this),
				$group = $t.closest('.footer__group'),
				$dropdown = $group.find('.footer__group-dropdown')
			;


			if ( !$t.hasClass('is-buzy') ){
				$t.addClass('is-buzy');
				$dropdown.slideToggle(300, ()=>{
					$group.toggleClass('is-open');
					$t.removeClass('is-buzy');
					$dropdown.removeAttr('style');
				})
			}

		})
		.on('click', '.nav__object-menu-title', function (e) {
			let $t = $(this),
				$group = $t.closest('.nav__object-menu'),
				$dropdown = $group.find('.nav__object-menu-list')
			;


			if ( !$t.hasClass('is-buzy') ){
				$t.addClass('is-buzy');
				$dropdown.slideToggle(300, ()=>{
					$group.toggleClass('is-open');
					$t.removeClass('is-buzy');
					$dropdown.removeAttr('style');
				})
			}

		})
		.on('click', '.js-show-more', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.parent(),
				$item = $wrap.find('.is-more-hidden'),
				offset = 10
			;

			if ( $item.length > offset ){
				$item.slice(0,offset).removeClass('is-more-hidden');
			} else {
				$item.removeClass('is-more-hidden');
			}

			$item = $wrap.find('.is-more-hidden');

			if ( $item.length < 1 ){
				$t.remove();
			}

		})

		.on('click', '.filter__tags .filter__tags-item_all', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.parent(),
				$input = $wrap.find('input')
			;

			$input.prop('checked', true);
		})

		.on('change', '.filter__select input', function (e) {
			e.preventDefault();
			let $t = $(this),
				$select = $t.closest('.filter__select'),
				$input = $select.find('input')
			;

			if ( $t.attr('type') === 'checkbox'){

				let $wrap = $t.closest('.filter'),
					$chacked = $input.filter(':checked'),
					$countGroup = $select.find('.filter__select-count'),
					$countWrap = $wrap.find('.filter__mobile-item_field-count')
				;

				$countGroup.html($chacked.length);
				$countWrap.html($chacked.length);

				if ( $chacked.length > 0 ){
					$select.addClass('is-selected');
				} else {
					$select.removeClass('is-selected');
				}

			} else if ( $t.attr('type') === 'radio'){

				let $val = $select.find('.filter__select-value').find('span'),
					val = $t.siblings('span').html(),
					index = $t.closest('.filter__select-item').index()
				;

				$val.html(val);

				if ( index > 0 ){
					$select.addClass('is-selected');
				} else {
					$select.removeClass('is-selected');
				}

			}


		})

		.on('click', '[data-show-filter]', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.closest('.filter'),
				$filter = $wrap.find($t.attr('data-show-filter'))
			;
			$filter.addClass('is-open');
			$b.addClass('is-filter-open');
		})

		.on('click', '.filter__title-close', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.closest('.filter')
			;

			$wrap.find('.is-open').removeClass('is-open');

			$b.removeClass('is-filter-open');
		})

		.on('click', '.filter__select-arrow', function (e) {
			let $t = $(this),
				$wrap = $t.closest('.filter__select')
			;

			if ( $wrap.hasClass('is-selected') && !$wrap.hasClass('is-open')){
				e.preventDefault();
				e.stopPropagation();

				let $input = $wrap.find('input').eq(0);

				if ( $input.attr('type') === 'radio' ){
					$input.prop('checked', true).trigger('change');
				} else if ($input.attr('type') === 'checkbox' ){
					$wrap.find('input').prop('checked', false).eq(0).trigger('change');
				}

			}
		})

		.on('click', '.js-form-clear', function (e) {
			let $t = $(this),
				$form = $t.closest('form'),
				$field = $form.find('.filter__field').filter('.is-open')
			;

			$field.each(function () {
				let $input = $(this).find('input').eq(0);

				if ( $input.attr('type') === 'radio' ){
					$input.prop('checked', true).trigger('change');
				} else if ($input.attr('type') === 'checkbox' ){
					$(this).find('input').prop('checked', false).eq(0).trigger('change');
				}
			})

		})

		/*.on('click', '.js-form-submit', function (e) {
			let $t = $(this),
				$form = $t.closest('form')
			;

			$form.submit();

		})*/

		.on('click', '.filter__select-value', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.closest('.filter__select')
			;
			$wrap.toggleClass('is-open');
		})

		.on('click', '.concealer__front-handler', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.closest('.concealer'),
				$back = $wrap.find('.concealer__back')
			;

			if ( !$t.hasClass('is-buzy') ){
				$t.addClass('is-buzy');

				$back.slideToggle(300, ()=>{
					$wrap.toggleClass('is-open');
					$t.removeClass('is-buzy');
					$back.removeAttr('style');
					try{$wrap.find('.js-schedule-all-slider').slick('setPosition');} catch (e){}
				});

				try{$wrap.find('.js-schedule-all-slider').slick('setPosition');} catch (e){}
			}
		})

		.on('click', '[data-popup-page]', function (e) {
			e.preventDefault();

			let $t = $(this),
				$popup = $('.popup_content'),
				popup,
				url = $t.attr('data-popup-page')
			;

			if ( !$popup.length ){
				popup = window.app.popup.create('.popup_content');
			} else {
				$popup.find('.popup__content').css('opacity', '0').html('');
			}

			popup = window.app.popup.open('.popup_content', '');

			$(popup).addClass('is-loading');

			$.ajax({
				url: url,
				cache: false
			}).done(function(data){
				//console.info(data);
				let $content = $(data).find('#content');
				$content.find('.section_breadcrumbs').remove();
				$(popup).find('.popup__content').css('opacity', '0').html($content.html());

				setTimeout(()=>{
					setContentSlick();
					viewPort.update();
					$(popup).find('.popup__content').removeAttr('style');
				}, 100)

			}).always(function(){
				$(popup).removeClass('is-loading');
			}).fail(function(err){

			});
		})

		.on('change', '#vacancyFile', function (e) {
			e.preventDefault();
			let $t = $(this),
				$form = $t.closest('form'),
				$fileList = $form.find('.form__field_filelist'),
				message = '',
				file = e.target.files.length ? e.target.files[0] : false
			;

			if ( file && !/\.(doc|docx)$/i.test( file.name ) ) {
				message = '<div class="c-red">Небходимо выбрать файл c расширением .doc, .docx</div>';
			} else if (file && file.size > 15000000 ) {
				message = '<div class="c-red">Размер файла не должен превышать 15 Мбайт</div>';
			} else if (file) {
				message = '<div>' + file.name + ' &nbsp; <a href="#" class="c-red js-file-del">УДАЛИТЬ</a></div>'
				$fileList.addClass('is-hidden');
				$t.prop('disabled', true);
			}

			// console.info(file);
			if (message) {
				$fileList.removeClass('is-hidden').html(message).find('.js-file-del').click(e=>{
					e.preventDefault();
					$fileList.addClass('is-hidden');
					$t.prop('disabled', false);
					this.value = '';
				});
			}

		})

	;


	// local filtering

	$('.js-form-local')

		.on('change', 'input', function () {
			if ( window.app.isDesktop ) setLocalFilter($(this).closest('.js-form-local'));
		})

		.on('click', '.js-form-submit', function (e) {
			e.preventDefault();
			if ( window.app.isMobile ) {
				setLocalFilter($(this).closest('.js-form-local'));
				$(this).closest('.js-form-local').find('.filter__title-close').click();
			}
		})

		.on('click', '.pagination__item, .pagination__control .btn', function (e) {
			e.preventDefault();
			let $t = $(this),
				$wrap = $t.closest('.pagination'),
				page = parseInt($t.html()) - 1
			;

			if ( $t.hasClass('btn_prev')){
				page = parseInt($wrap.find('.is-active').html()) - 2
			} else if ( $t.hasClass('btn_next')){
				page = parseInt($wrap.find('.is-active').html())
			}

			setLocalFilter($t.closest('.js-form-local'), page);
		})

		.each(function () {
			setLocalFilter($(this));
		})
	;




	fixMenu();
	function fixMenu() {

		if ( !$('.section_menu').length ) return false;

		let f = ()=>{
			let $section = $('.section_menu'),
				$window = $(window),
				$inner = $section.find('.tabs'),
				$header = $('.header'),
				offsetScroll = $section.offset().top,
				offsetTop = $header.height(),
				ws = $window.scrollTop()
			;



			if ( offsetScroll - offsetTop < ws && !$inner.hasClass('is-fixed')){
				$inner.addClass('is-fixed');
			} else if (offsetScroll - offsetTop >= ws && $inner.hasClass('is-fixed')) {
				$inner.removeClass('is-fixed');
			}
		};

		f();
		$(window).on('scroll resize', f);

	}


	function setAdaptiveSlick() {

		$('.js-adaptive-slider').each(function () {
			let $slider = $(this),
				$wrap = $slider.parent(),
				$control = $wrap.find('.slider-control'),
				variableWidthDesktop = $slider.hasClass('gallery__list') || $wrap.hasClass('announce_news'),
				responsive = false
			;

			if ( variableWidthDesktop ){
				responsive = [
					{
						breakpoint: 1260,
						settings: {
							// variableWidth: false,
							adaptiveHeight: false,
							dots: true
						}
					}
				]
			} else {
				responsive = [
					{
						breakpoint: 1260,
						settings: {
							variableWidth: false,
							adaptiveHeight: false,
							slidesToShow: 3
						}
					},
					{
						breakpoint: 1660,
						settings: {
							variableWidth: false,
							adaptiveHeight: false,
							slidesToShow: 4
						}
					}
				]
			}

			if ( !$control.length ) return;

			try{
				$slider.slick('setPosition')
			} catch (e) {
				$slider
					.on('init', function (event, slick) {
						$wrap.find('.slider-control__count')
							.html((slick.currentSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');

						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');

						if ( slick.slideCount > slick.options.slidesToShow){
							$control.removeClass('is-hidden');
						} else {
							$control.addClass('is-hidden');
						}
					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$wrap.find('.slider-control__count')
							.html((nextSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.on('breakpoint', function (event, slick) {
						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');
						if ( slick.slideCount > slick.options.slidesToShow){
							$control.removeClass('is-hidden');
						} else {
							$control.addClass('is-hidden');
						}
					})
					.slick({
						dots: false,
						infinite: false,
						speed: 300,
						prevArrow: $wrap.find('.slider-control__arrow_prev'),
						nextArrow: $wrap.find('.slider-control__arrow_next'),
						variableWidth: true,
						swipeToSlide: true,
						adaptiveHeight: true,
						slidesToScroll: 1,
						appendDots: $wrap.find('.slider-control__dots'),
						mobileFirst: true,

						responsive: responsive
					});
			}

		});

	}

	function setScheduleContentSlick(destroy) {

		$('.js-schedule-content').each(function () {

			let $slider = $(this),
				$wrap = $slider.parent(),
				$control = $wrap.find('.slider-control')
			;

			if ( !$control.length ) return;

			if ( destroy ){

				try{ $slider.slick('unslick');} catch (e){}

			} else {
				$slider
					.on('init', function (event, slick) {
						$wrap.find('.slider-control__count')
							.html((slick.currentSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$wrap.find('.slider-control__count')
							.html((nextSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.slick({
						dots: false,
						infinite: false,
						speed: 300,
						swipeToSlide: false,
						slidesToShow: 1,
						slidesToScroll: 1,
						adaptiveHeight: true,
						prevArrow: $wrap.find('.slider-control__arrow_prev'),
						nextArrow: $wrap.find('.slider-control__arrow_next')
					});
			}

		});

	}

	function setContentSlick() {

		$('.js-content-slider').each(function () {
			let $slider = $(this),
				$wrap = $slider.parent(),
				$control = $wrap.find('.slider-control'),
				responsive = false
			;

			responsive = [
				{
					breakpoint: 1260,
					settings: {
						// variableWidth: false,
						adaptiveHeight: false,
						dots: true
					}
				}
			];

			if ( !$control.length ) return;

			try{
				$slider.slick('setPosition')
			} catch (e) {
				$slider
					.on('init', function (event, slick) {
						$wrap.find('.slider-control__count')
							.html((slick.currentSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');

						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');

						if ( slick.slideCount > slick.options.slidesToShow){
							$control.removeClass('is-hidden');
						} else {
							$control.addClass('is-hidden');
						}
					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$wrap.find('.slider-control__count')
							.html((nextSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.on('breakpoint', function (event, slick) {
						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');
						if ( slick.slideCount > slick.options.slidesToShow){
							$control.removeClass('is-hidden');
						} else {
							$control.addClass('is-hidden');
						}
					})
					.slick({
						dots: false,
						infinite: false,
						speed: 300,
						prevArrow: $wrap.find('.slider-control__arrow_prev'),
						nextArrow: $wrap.find('.slider-control__arrow_next'),
						variableWidth: false,
						slidesToShow: 1,
						swipeToSlide: true,
						adaptiveHeight: true,
						slidesToScroll: 1,
						appendDots: $wrap.find('.slider-control__dots'),
						mobileFirst: true,

						responsive: responsive
					});
			}

		});

	}

	function setBlockSlick() {

		$('.js-block-slider').each(function () {
			let $slider = $(this),
				$wrap = $slider.parent(),
				$control = $wrap.find('.slider-control')
			;

			if ( !$control.length ) return;

			try{
				$slider.slick('setPosition')
			} catch (e) {
				$slider
					.on('init', function (event, slick) {
						$wrap.find('.slider-control__count')
							.html((slick.currentSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');

					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$wrap.find('.slider-control__count')
							.html((nextSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.on('breakpoint', function (event, slick) {
						if ( $control.hasClass('slider-control_aside') ) slick.$list.addClass('slick-overflow');
					})
					.slick({
						dots: false,
						infinite: false,
						swipeToSlide: true,
						speed: 300,
						prevArrow: $control.find('.slider-control__arrow_prev'),
						nextArrow: $control.find('.slider-control__arrow_next'),
						variableWidth: true,
						slidesToScroll: 1,
						mobileFirst: true,

						responsive: [
							{
								breakpoint: 1260,
								settings: {
									variableWidth: false,
									adaptiveHeight: false,
									slidesToShow: 5
								}
							},
							{
								breakpoint: 1660,
								settings: {
									variableWidth: false,
									adaptiveHeight: false,
									slidesToShow: 7
								}
							}
						]
					});
			}

		});
	}

	function setMobileSlick(destroy) {

		$('.js-mobile-slider').each(function () {
			let $slider = $(this),
				$wrap = $slider.parent(),
				$control = $wrap.find('.slider-control')
			;

			if ( !$control.length ) return;

			if ( destroy ){

				try{ $slider.slick('unslick');} catch (e){}

			} else {
				$slider
					.on('init', function (event, slick) {
						$wrap.find('.slider-control__count')
							.html((slick.currentSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
						$wrap.find('.slider-control__count')
							.html((nextSlide+1) + '/<span class="c-red">' + slick.slideCount + '</span>');
					})
					.slick({
						dots: false,
						infinite: false,
						swipeToSlide: true,
						speed: 300,
						prevArrow: $control.find('.slider-control__arrow_prev'),
						nextArrow: $control.find('.slider-control__arrow_next'),
						variableWidth: true,
						slidesToScroll: 1
					});
			}
		});
	}


	function setMenuSlide(unslick) {

		$('.tabs__menu .inner').each(function () {
			let $menu = $(this);

			if ( !$menu.length ) return;

			if ( unslick === false ){
				try{
					$menu.slick('unslick')
				}
				catch(e){}
			} else if ( $menu[0].offsetWidth < $menu[0].scrollWidth) {
				$menu.slick({
					dots: false,
					infinite: false,
					speed: 300,
					slidesToShow: 14,
					prevArrow: '<div class="slider-control__arrow slider-control__arrow_prev"></div>',
					nextArrow: '<div class="slider-control__arrow slider-control__arrow_next"></div>',
					swipeToSlide: true,

					responsive: [
						{
							breakpoint: 1660,
							settings: {
								slidesToShow: 10
							}
						}
					]
				});
			}
		});


	}


	function scrollBarWidth(){
		let scrollDiv = document.createElement('div'), sbw;
		scrollDiv.className = 'scroll_bar_measure';
		$(scrollDiv).css({
			width: '100px',
			height: '100px',
			overflow: 'scroll',
			position: 'absolute',
			top: '-9999px'
		});
		document.body.appendChild(scrollDiv);
		sbw = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		document.body.removeChild(scrollDiv);
		return sbw;
	}


	function setLocalFilter($wrap, p) {

		if (!($wrap && $wrap.length )) return false;

		let $form = $wrap.find('form'),
			$pagination = $wrap.find('.pagination'),
			filter = {},
			$item = $('[data-filter]'),
			visible = 0,
			page = p || 0,
			SLICE = +$wrap.attr('data-slice') || 10
		;

		$form.find('input').each(function () {
			let $t = $(this);
			if ( $t.attr('type') === 'checkbox'  ){
				if ( !filter[$t.attr('name')] ) filter[$t.attr('name')] = [];
				if ( $t.prop('checked') ) filter[$t.attr('name')].push($t.val());
			} else if ( $t.attr('type') === 'radio' ){
				if ( !filter[$t.attr('name')] ) filter[$t.attr('name')] = [];
				if ($t.prop('checked')) filter[$t.attr('name')].push($t.val());
			} else {
				if ( !filter[$t.attr('name')] ) filter[$t.attr('name')] = [];
				filter[$t.attr('name')].push($t.val());
			}
		});


		$item.addClass('is-hidden').each(function () {
			let $t = $(this),
				data = {},
				i = 0,
				entrance = true
			;

			try{
				data = JSON.parse($t.attr('data-filter').replace(/\'/ig, '"'));
			} catch (e){
				console.error(e, 'проверьте аттрибут data-filter: ', $t);
			}

			// console.info('data ', data);

			for (i; i < Object.keys(filter).length; i++){
				if ( filter[Object.keys(filter)[i]].length ){

					if (!data[Object.keys(data)[i]].entrance(filter[Object.keys(filter)[i]])){
						entrance = false;
						break;
					}
				}
			}


			if ( entrance ){
				visible++;
				if ( visible > page * SLICE && visible <= SLICE + (page * SLICE) ){
					$t.removeClass('is-hidden');
				}
			}
		});

		// pagination

		if ( $pagination.length && visible > 0 ){
			let pageHTML = '<div class="pagination__list">',
				pages = [],
				pagesLength = Math.ceil(visible / SLICE),
				pagesDelta = 2,
				dotted = false
			;

			for (let i = 0; i < pagesLength; i++){
				if ( i === 0 || ( page < 5 && i < 5 ) || (i >= page - pagesDelta && i <= page + pagesDelta) || i === pagesLength - 1 ){
					pages.push(i+1);
					dotted = false;
					pageHTML += '<a class="pagination__item ' + ( i === page ? 'is-active' : '') + '" href="#">' + (i + 1) + '</a>';
				} else if (!dotted){
					dotted = true;
					pages.push(false);
					pageHTML += '<div class="pagination__dots">. . .</div>';
				}
			}

			pageHTML += '</div> ';
			pageHTML += '<div class="pagination__control">';
			pageHTML += '<a class="btn btn_prev ' + ( page === 0 ? 'is-disabled' : '' ) + '" href="#">ПРЕДЫДУЩАЯ</a>';
			pageHTML += '<a class="btn btn_next ' + ( page === pagesLength - 1 ? 'is-disabled' : '' ) + '" href="#">СЛЕДУЮЩАЯ</a>';
			pageHTML += '</div>';

			// console.info(pages);

			$pagination.html(pageHTML);
		}
	}

	window.initMap = function () {

		$('.js-map').each(function () {
			let $map = $(this),
				map,
				coord = $map.attr('data-coordinates').split(',').map(n=>(+n)),
				baloon = $map.attr('data-baloon'),
				dx = [[-0.001, 0],[0, -0.0023],[0, -0.0023]],
				isClub1 = $map.closest('.clubmap__item_1').length,
				isClub2 = $map.closest('.clubmap__item_2').length
			;

			map = new google.maps.Map($map[0], {
				center: isClub1 || isClub2 ? {lat: coord[0] + dx[window.app.viewPort][0], lng: coord[1] + dx[window.app.viewPort][1]} : {lat: coord[0], lng: coord[1]},
				zoom: 17,
				disableDefaultUI: true,
				zoomControl: true,
				scrollwheel: false,
				backgroundColor: 'none'
			});

			new google.maps.Marker({
				position: {lat: coord[0], lng: coord[1]},
				map: map,
				icon: baloon
			});

		});
	}

});
