import $ from 'jquery';

export default class Viewport {
	constructor(options) {

		this._polyfill();

		Object.assign(this._options = {}, this._default(), options);
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.init();
			});
		} else {
			this.init();
		}
	}

	_polyfill() {
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}
	}

	_default() {
		return {
			slideTime: 500,
			heightElements: [],
			dataAttr: 'data-part',
			activeClass: 'active',
			menu: '.tabs__menu',
			offset: 5,
			sliding: false,
			timer: null
		}
	}

	setMenuItemPosition($el){
		let $menu = $el.closest(this._options.menu),
			t = this._options.sliding ? this._options.slideTime : 0,
			distance = $el.position().left
		;

		clearTimeout(this._options.timer);

		this._options.timer = setTimeout(()=>{
			$menu
				//.stop(true, true)
				.animate({
					scrollLeft:distance
				});
		}, t);

	}

	onScroll(){
		let scrollPos = $(document).scrollTop(), that = this;

		$('[' + that._options.dataAttr +']').each(function () {

			let $t = $(this),
				group = $t.attr(that._options.dataAttr).match(/([\S]+#)/ig) ? $t.attr(that._options.dataAttr).match(/([\S]+#)/ig)[0].replace('#','') : 'pagenavgroup',
				$section = $($t.attr(that._options.dataAttr).match(/(#[\S]+)/ig)[0]),
				h = 0
			;

				for (let i = 0; i < that._options.heightElements.length; i++){
					h += $(that._options.heightElements[i]).outerHeight(true);
				}

				let onScreen = ($section.position().top - h - that._options.offset <= scrollPos ) && ( $section.offset().top - h - that._options.offset + $section.height() > scrollPos);

			if ( $section.length && onScreen && !$t.hasClass(that._options.activeClass) && group ) {
				$('[' + that._options.dataAttr + ' ^= ' + group + ']').removeClass(that._options.activeClass);
				$t.addClass(that._options.activeClass);

				that.setMenuItemPosition($t);
			} else if ( $section.length && !onScreen && $t.hasClass(that._options.activeClass) ) {
				$t.removeClass(that._options.activeClass);

			}

		});
	}



	init(){

		let that = this;

		if ( $('[' + that._options.dataAttr +']').length ){
			this.onScroll();
			$(window).on('scroll', ()=>{this.onScroll();});

			$('body').on('click', '[' + that._options.dataAttr +']', function(e){
				e.preventDefault();

				that._options.sliding = true;

				let $t = $(this),
					dataAttr = $t.attr(that._options.dataAttr),
					$section = $(dataAttr.match(/(#[\S]+)/ig)[0]),
					h = 0
				;

				for (let i = 0; i < that._options.heightElements.length; i++){
					h += $(that._options.heightElements[i]).outerHeight(true);
				}

				$('html,body').animate({scrollTop:$section.offset().top - h }, that._options.slideTime, ()=>{
					that._options.sliding = false;
				});
			});
		}

	}
}

