import $ from 'jquery';

export default class TimeCounter {
	constructor(options) {

		String.prototype.declension = function() {
			let cases = [2, 0, 1, 1, 1, 2],num = Number(this);
			if ( isNaN(num) ){ console.error('declension must be a Number');}
			else { return arguments[(num%100>4 && num%100<20)? 2 : cases[(num%10<5)?num%10:5]]; }
		};

		Number.prototype.declension = function() {
			let cases = [2, 0, 1, 1, 1, 2],num = Number(this);
			return arguments[(num%100>4 && num%100<20)? 2 : cases[(num%10<5)?num%10:5]];
		};

		this.init();

	}

	convertTime(t){
		let time = t || 0, res = {};
		res.days = {};
		res.days.active = Math.floor(time / (3600 * 24)) > 0;
		res.days.value = Math.floor(time / (3600 * 24));
		res.days.string = Math.floor(time / (3600 * 24)).toString();

		if ( res.days.string.length < 2 ) res.days.string = '0' + res.days.string;

		res.hours = {};
		res.hours.active = Math.floor(time / 3600) > 0;
		res.hours.value = Math.floor((time / (3600) % 24));
		res.hours.string = ('0' + Math.floor((time / (3600) % 24))).slice(-2);

		res.minutes = {};
		res.minutes.active = Math.floor(time / 60) > 0;
		res.minutes.value = Math.floor(time % 3600 / 60);
		res.minutes.string = ('0' + Math.floor(time % 3600 / 60)).slice(-2);

		res.seconds = {};
		res.seconds.active = Math.floor(time) > 0;
		res.seconds.value = Math.floor(time % 3600 % 60);
		res.seconds.string = ('0' + Math.floor(time % 3600 % 60)).slice(-2);
		return res;
	}

	setValue($obj, time){

		let $day = $obj.find('.timer__item_day'),
			$hour = $obj.find('.timer__item_hour'),
			$min = $obj.find('.timer__item_min'),
			$sec = $obj.find('.timer__item_sec'),
			counter = this.convertTime(time)
		;

		$day.find('.timer__value').html(counter.days.string);
		$day.find('.timer__name').html(counter.days.value.declension('день', 'дня', 'дней'));
		if ( counter.days.active && !$day.hasClass('is-active')) { $day.addClass('is-active'); }
		else if (!counter.days.active && $day.hasClass('is-active')) { $day.removeClass('is-active'); }

		$hour.find('.timer__value').html(counter.hours.string);
		$hour.find('.timer__name').html(counter.hours.value.declension('час', 'часа', 'часов'));
		if ( counter.hours.active && !$hour.hasClass('is-active') ) { $hour.addClass('is-active'); }
		else if (!counter.hours.active && $hour.hasClass('is-active')) { $hour.removeClass('is-active'); }

		$min.find('.timer__value').html(counter.minutes.string);
		$min.find('.timer__name').html(counter.minutes.value.declension('минута', 'минуты', 'минут'));
		if ( counter.minutes.active && !$min.hasClass('is-active') ) { $min.addClass('is-active'); }
		else if (!counter.minutes.active && $min.hasClass('is-active')) { $min.removeClass('is-active'); }

		$sec.find('.timer__value').html(counter.seconds.string);
		$sec.find('.timer__name').html(counter.seconds.value.declension('секунда', 'секунды', 'секунд'));
		if ( counter.seconds.active && !$sec.hasClass('is-active') ) { $sec.addClass('is-active'); }
		else if (!counter.seconds.active && $sec.hasClass('is-active')) { $sec.removeClass('is-active'); }
	}

	init(){
		let that = this;

		$('.timer').each(function () {
			let $t = $(this),
				time = parseInt($t.attr('data-counter-time')),
				timer = null
			;

			if ( !$t.hasClass('is-init')){
				$t.addClass('is-init');
				timer = setInterval(()=>{
					if ( time <= 0 ) clearInterval(timer);
					that.setValue($t, time);
					time--;
				}, 1000);

			}
		})

	}
}
