
export default class AlignHeight {
	constructor(options) {
		this._polyfill();
		Object.assign(this._options = {}, this._default(), options);
		/*if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', () => {
				this.init();
			});
		} else {
			this.init();
		}*/
	}

	_polyfill() {

		//closest
		if (!Element.prototype.closest) {
			Element.prototype.closest = function (css) {
				let node = this;
				while (node) {
					if (node.matches(css)) return node;
					else node = node.parentElement;
				}
				return null;
			};
		}

		//assign
		if (!Object.assign) {
			Object.defineProperty(Object, 'assign', {
				enumerable: false,
				configurable: true,
				writable: true,
				value: function (target, firstSource) {
					'use strict';
					if (target === undefined || target === null) {
						throw new TypeError('Cannot convert first argument to object');
					}

					let to = Object(target);
					for (let i = 1; i < arguments.length; i++) {
						let nextSource = arguments[i];
						if (nextSource === undefined || nextSource === null) {
							continue;
						}

						let keysArray = Object.keys(Object(nextSource));
						for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
							let nextKey = keysArray[nextIndex],
								desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
							if (desc !== undefined && desc.enumerable) {
								to[nextKey] = nextSource[nextKey];
							}
						}
					}
					return to;
				}
			});
		}
	}

	_default() {
		return {
			wrap: '',
			item: ''
		}
	}

	alignHeight(){
		let wrap = document.querySelectorAll(this._options.wrap);
		Array.from(wrap).forEach(wrap => {
			let item = wrap.querySelectorAll(this._options.item), h = 0;
			Array.from(item).forEach(item => {
				item.style.minHeight = '';
				h = item.offsetHeight > h ? item.offsetHeight : h;
			});
			Array.from(item).forEach(item => {
				item.style.minHeight = h + 'px';
			});
		});
	}

	clearHeight(){
		let wrap = document.querySelectorAll(this._options.wrap);
		Array.from(wrap).forEach(wrap => {
			let item = wrap.querySelectorAll(this._options.item);
			Array.from(item).forEach(item => {
				item.style.minHeight = '';
			});
		});
	}

	init(){
		let that = this;

		that.timer = null;
		that.alignHeight();

		window.addEventListener('resize', ()=>{
			if ( that.timer ) clearTimeout(that.timer);
			that.timer = setTimeout(()=>{
				that.alignHeight();
			}, 100);
		});
	}
}
